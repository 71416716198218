import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/404';
import VenuePage from './pages/Venue';
import EventPage from './pages/Event';
import Profile from './pages/Profile';
import Authflow from './pages/Authflow';
import CreateUser from './pages/CreateUser';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import Redeem from './pages/Redeem';
import Footer from './components/Footer';

function App() {
  return (
    <Box
      textAlign="top"
      fontSize="xl"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      <BrowserRouter>
        <Box flexGrow={1}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login?redirect=:pageName" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/authflow" element={<Authflow />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/redeem" element={<Redeem />} />
            <Route path="/venues/:venueName" element={<VenuePage />} />
            <Route path="/events/:eventName" element={<EventPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <Footer />
      </BrowserRouter>
    </Box>
  );
}

export default App;
