import { extendTheme } from '@chakra-ui/react';

const ButtonTheme = extendTheme({
  components: {
    Button: {
      variants: {
        solid: {
          bg: '#007fff',
          color: 'white',
          _hover: {
            bg: '#0066cc', // Darker blue upon hover
            _disabled: {
              bg: '#005ca6', // Slightly less dark blue when disabled and hovered
            },
          },
          _active: {
            bg: '#004c99', // Even darker blue for click
          },
          _disabled: {
            bg: '#007fff', // Appropriate color when disabled
            color: 'gray.200',
            cursor: 'not-allowed',
          },
        },
        default: props => ({
          bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.200',
          color: props.colorMode === 'dark' ? 'white' : 'gray.800',
          _hover: {
            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.300',
          },
          _active: {
            bg: props.colorMode === 'dark' ? 'gray.600' : 'gray.400',
          },
          _disabled: {
            opacity: 0.5,
            cursor: 'not-allowed',
          },
        }),
      },
      defaultProps: {
        variant: 'default',
      },
    },
  },
});

export default ButtonTheme;
