import { Text, ChakraProvider } from '@chakra-ui/react';

function Success() {
  return (
    <ChakraProvider>
      <Text>
        Your payment succeeded! Download the Wave App to see your purchased pass
        or ticket.
      </Text>
    </ChakraProvider>
  );
}

export default Success;
