import React, { useEffect } from 'react';
import { ChakraProvider, theme, Image } from '@chakra-ui/react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function Login() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageName = queryParams.get('redirect');
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  const app = firebase.initializeApp(firebaseConfig);
  app.auth();
  useEffect(() => {
    if (pageName == null) {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', {
        signInOptions: [
          {
            recaptchaParameters: {
              size: 'invisible', // 'invisible' or 'compact'
              badge: 'none', //' bottomright' or 'inline' applies to invisible.
            },
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            requireDisplayName: false,
          },
        ],
        signInSuccessUrl: `/authflow`,
        privacyPolicyUrl: '/privacy',
      });
      document.body.style.background = '#141416';
    } else {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', {
        signInOptions: [
          {
            recaptchaParameters: {
              size: 'invisible', // 'invisible' or 'compact'
              badge: 'none', //' bottomright' or 'inline' applies to invisible.
            },
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            requireDisplayName: false,
          },
        ],
        signInSuccessUrl: `/authflow?redirect=${pageName}`,
        privacyPolicyUrl: '/privacy',
      });
      document.body.style.background = '#141416';
    }
  }, [pageName]);

  return (
    <ChakraProvider theme={theme}>
      {isDesktop && (
        <div>
          <Image
            src="/blurred_background.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }}
          />
          <div
            id="content-container"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              textAlign: 'center',
              color: '#fff',
            }}
          >
            <h1 style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}>
              Register / Sign In
            </h1>
            <div style={{ color: 'white' }}>
              VIP Experiences. Your Favorite Artists. <br /> Welcome to Wave.{' '}
              <br />
              Enter your phone number to make an account or sign in.
              <br />
              <br />
            </div>
            <div id="firebaseui-auth-container"></div>
            <div style={{ height: 80 }} />
          </div>
        </div>
      )}
      {/* {isTablet && ()} */}
      {(isTablet || isMobile) && (
        <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
          <Image
            src="/blurred_background.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }}
          />
          <div
            id="content-container"
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              textAlign: 'center',
              color: '#fff',
            }}
          >
            <div>
              <h1 style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}>
                Register / Sign In
              </h1>
              <div style={{ color: 'white' }}>
                VIP Experiences. Your Favorite Artists. <br /> Welcome to Wave.{' '}
                <br />
                Enter your phone number to make an account or sign in.
                <br />
                <br />
              </div>
              <div
                style={{ paddingBottom: 50 }}
                id="firebaseui-auth-container"
              ></div>
              {/* <div style={{height: 250}}/> */}
            </div>
          </div>
        </div>
      )}
    </ChakraProvider>
  );
}

export default Login;
